// apiService.js

import resume from './cmsserver/Resume';
import jobApplication from './cmsserver/JobApplication';
import recruiter from './cmsserver/Recruiter';
import company from './cmsserver/Company';


export default {
    resume,
    jobApplication,
    recruiter,
    company
};
