import { Loader2 } from 'lucide-react'
import React, { useState, useContext, useEffect } from 'react'
import {
    PlusCircle,
} from "lucide-react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Button } from '@/components/ui/button'
import { toast } from 'sonner';
import CmsApi from '../../../../service/CmsApi'
import { JobApplicationInfoContext } from '@/context/JobApplicationInfoContext'
import { useParams } from 'react-router-dom';

const BindRecruiter = () => {
    const params = useParams();
    const { jobApplication, setJobApplication } = useContext(JobApplicationInfoContext)
    const [recruiters, setRecruiters] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        jobApplication && GetRecruiterList()
    }, [jobApplication])

    const GetRecruiterList = () => {
        CmsApi.recruiter.getRecruiters(jobApplication?.userEmail)
            .then(resp => {
                console.log('CmsApi.recruiter.getRecruiters ...')
                console.log(resp.data.data)
                if (resp.data.data) {
                    setRecruiters(resp.data.data);
                }

            })
    }

    const handleStateChange = (value) => {
        const selectedRecruiter = recruiters.find(recruiter => recruiter.id === value);
        setFormData({
            ...formData,
            recruiter: value
        });
        setJobApplication({
            ...jobApplication,
            recruiter: selectedRecruiter
        });
    }

    const onSave = () => {
        setLoading(true)
        console.log('formData--')
        console.log(formData)
        const data = {
            data: formData
        }
        CmsApi.jobApplication.updateJobApplication(params?.jobApplicationId, data).then(resp => {
            console.log(resp);
            setLoading(false);
            setOpenDialog(false)
            toast("Job Application Details updated")
        }, (error) => {
            setLoading(false);
            setOpenDialog(false)
            toast("Job Application meet error")
        })

    }


    return (
        <div>
            <div className="ml-auto flex items-center gap-2" onClick={() => setOpenDialog(true)}>
                <Button >
                    <span className="sr-only sm:not-sr-only sm:whitespace-nowrap ">
                        {jobApplication?.recruiter ? jobApplication?.recruiter.name : 'Add recruiter'}
                    </span>
                </Button>
            </div>

            {recruiters && <Dialog open={openDialog}>

                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Change Recruiter</DialogTitle>
                        <DialogDescription>
                            <Select name="recruiter" defaultValue={jobApplication?.recruiter?.id} onValueChange={handleStateChange}>
                                <SelectTrigger>
                                    <SelectValue placeholder="Select a recruiter" />
                                </SelectTrigger>
                                <SelectContent>
                                    {recruiters.map(recruiter => (
                                        <SelectItem key={recruiter.id} value={recruiter.id}>
                                            {recruiter.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </DialogDescription>
                        <div className='flex justify-end gap-5'>
                            <Button onClick={() => setOpenDialog(false)} variant="ghost">Cancel</Button>
                            <Button disabled={!jobApplication || loading}
                                onClick={() => onSave()} >
                                {loading ?
                                    <Loader2 className='animate-spin' /> : 'Update'
                                }
                            </Button>
                        </div>
                    </DialogHeader>
                </DialogContent>
            </Dialog>}

        </div>
    )
}

export default BindRecruiter