import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyInfoContext } from '@/context/CompanyInfoContext';
import CmsApi from './../../../../../service/CmsApi';
import FormSection from '../../components/FormSection';


const EditCompany = () => {
    const { companyId } = useParams();
    const [company,setCompany]=useState();

    useEffect(() => {
        GetJobApplication();
    }, [])

    const GetJobApplication=()=>{
        console.log('companyId:' + companyId)
        CmsApi.company.getCompanyById(companyId).then(resp=>{
          console.log(resp.data.data);
          setCompany(resp.data.data);
        })
    }

    return (
        <CompanyInfoContext.Provider value={{company, setCompany}}>
            <div className='grid grid-cols-1 md:grid-cols-2 p-10 gap-10'>
                {/* Form Section  */}
                <FormSection />
                {/* Preview Section  */}
                

            </div>
        </CompanyInfoContext.Provider>
    )
}

export default EditCompany