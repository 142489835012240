import axios from 'axios';

const API_KEY = import.meta.env.VITE_STRAPI_API_KEY;
const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + "/api/",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_KEY}`
  }
});

const createRecruiter = (data) => axiosClient.post('/recruiters', data);

const getRecruiters = (userEmail) => axiosClient.get('/recruiters?filters[userEmail][$eq]=' + userEmail);

const updateRecruiter = (id, data) => axiosClient.put('/recruiters/' + id, data);

const getRecruiterById = (id) => axiosClient.get('/recruiters/' + id + "?populate=*");

const deleteRecruiter = (id) => axiosClient.delete('/recruiters/' + id);

const GetRecruiterCount = async (userEmail) => {
  try {
    const response = await axiosClient.get(`/recruiters?filters[userEmail][$eq]=${userEmail}`);
    return response.data.data.length;
  } catch (error) {
    console.error('Error fetching recruiters count:', error);
    throw error;
  }
};

const RECRUITER_TYPES = [
  'internal',
  'agency'
];

export default {
  createRecruiter,
  getRecruiters,
  updateRecruiter,
  getRecruiterById,
  deleteRecruiter,
  GetRecruiterCount,
  RECRUITER_TYPES
};
