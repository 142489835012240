"use client"

import React, { useState } from 'react';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { ArrowUpDown, ChevronDown, MoreHorizontal } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import CmsApi from './../../../../service/CmsApi';
import { Link, useNavigate } from 'react-router-dom'
import AddJobApplication from "./AddJobApplication"



const ApplicationTable = ({ jobApplications, handleStatusChange, handleDeleteClick }) => {
    const columns = [
        {
            accessorKey: "jobTitle",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Job Title
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => {
                const item = row.original;
                return (
                    item.jdUrl ? (
                        <a href={item.jdUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                            {item.jobTitle}
                        </a>
                    ) : (
                        <span className="text-gray-700">{item.jobTitle}</span>
                    )
                )
            },
        },
        {
            accessorKey: "state",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Status
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => {

                const item = row.original
                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                aria-haspopup="true"
                                size="sm"
                                variant="outline"
                            >
                                {row.getValue("state")}
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start">
                            {CmsApi.jobApplication.JOB_STATES.map(state => (
                                <DropdownMenuItem
                                    key={state}
                                    onClick={() => handleStatusChange(item.documentId, state)}
                                >
                                    {state}
                                </DropdownMenuItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },

        {
            accessorKey: "priority",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Priority
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => <div >{row.getValue("priority")}</div>,
        },
        {
            accessorKey: "company",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Company
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => <div >{row.getValue("company")}</div>,
        },
        {
            accessorKey: "city",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        City
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => {
                return <div className="text-right font-medium">{row.getValue("city")}</div>
            },
        },
        {
            accessorKey: "tag",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Tag
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => {
                //   const amount = parseFloat(row.getValue("tag"))

                //   // Format the amount as a dollar amount
                //   const formatted = new Intl.NumberFormat("en-US", {
                //     style: "currency",
                //     currency: "USD",
                //   }).format(amount)

                //   return <div className="text-right font-medium">{formatted}</div>
                return <div className="text-right font-medium">{row.getValue("tag")}</div>
            },
        },
        {
            accessorKey: "applyDate",
            header: ({ column }) => {
                return (
                    <Button
                        variant="ghost"
                        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                    >
                        Apply On
                        <ArrowUpDown className="ml-2 h-4 w-4" />
                    </Button>
                )
            },
            cell: ({ row }) => {
                const date = new Date(row.getValue("applyDate"));
                const localDateString = date.toLocaleDateString();
                return <div className="lowercase">{localDateString}</div>
            },
        },

        {
            accessorKey: "recruiter",
            header: "Recruiter",
            cell: ({ row }) => {
                const item = row.original
                const name = item.recruiter ? item.recruiter.name : '';
                return <div className="capitalize">{name}</div>;
            },
        },
        {
            accessorKey: "updatedAt",
            header: "Update At",
            cell: ({ row }) => {
                const date = new Date(row.getValue("updatedAt"));
                const localDateString = date.toLocaleString();
                return <div className="capitalize">{localDateString}</div>;
            },
        },
        {
            id: "actions",
            enableHiding: false,
            cell: ({ row }) => {
                const item = row.original

                return (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                aria-haspopup="true"
                                size="icon"
                                variant="ghost"
                            >
                                <MoreHorizontal className="h-4 w-4" />
                                <span className="sr-only">Toggle menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Actions</DropdownMenuLabel>
                            <Link to={'/dashboard/jobapplication/' + item.documentId + "/edit"}><DropdownMenuItem>Edit</DropdownMenuItem></Link>
                            <DropdownMenuItem className="text-red-500 hover:text-red-700" onClick={() => handleDeleteClick(item)}>Delete</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )
            },
        },
    ]

    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    const [columnVisibility, setColumnVisibility] =
        useState({})
    const [rowSelection, setRowSelection] = useState({})

    const table = useReactTable({
        data: jobApplications,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })

    const [filterColumn, setFilterColumn] = useState("company");
    const [filterValue, setFilterValue] = useState("");

    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);
        table.getColumn(filterColumn)?.setFilterValue(value);
    };

    const handleColumnChange = (selectedColumn) => {
        // const selectedColumn = event.target.value;

        setFilterColumn(selectedColumn);

        setFilterValue("");
        table.getColumn(selectedColumn)?.setFilterValue("");
    };

    return (
        <div className="w-full">
            <div className="flex items-center py-4">
                <div className="flex gap-2 items-baseline">
                    <AddJobApplication />
                    <div className="flex items-center gap-4">

                        <Select onValueChange={handleColumnChange} value={filterColumn}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a column" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="state">Status</SelectItem>
                                <SelectItem value="priority">Priority</SelectItem>
                                <SelectItem value="company">Company</SelectItem>
                                <SelectItem value="jobTitle">Job Title</SelectItem>
                                <SelectItem value="city">City</SelectItem>
                                <SelectItem value="tag">Tag</SelectItem>
                                <SelectItem value="applyDate">Apply On</SelectItem>



                            </SelectContent>
                        </Select>

                        <Input
                            placeholder={`Filter by ${filterColumn}...`}
                            value={filterValue}
                            onChange={handleFilterChange}
                            className="max-w-sm"
                        />
                    </div>
                </div>

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto">
                            Columns <ChevronDown className="ml-2 h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {table
                            .getAllColumns()
                            .filter((column) => column.getCanHide())
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className="capitalize"
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) =>
                                            column.toggleVisibility(!!value)
                                        }
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                )
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredRowModel().rows.length} row(s).
                </div>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        Previous
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ApplicationTable
