import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import SignInPage from './auth/sign-in/index.jsx'
import Home from './home/index.jsx'
import Dashboard from './dashboard/index.jsx'
import { ClerkProvider } from '@clerk/clerk-react'
import EditResume from './dashboard/resume/[resumeId]/edit/index.jsx'
import ViewResume from './my-resume/[resumeId]/view/index.jsx'
import ResumePage from './dashboard/resume/index.jsx'
import JobApplicationPage from './dashboard/jobapplication/index.jsx'
import EditJobApplication from './dashboard/jobapplication/[jobApplicationId]/edit/index.jsx'
import initializeGA from './ga';
import RecruiterPage from './dashboard/recruiter/index.jsx'
import EditRecruiter from './dashboard/recruiter/[recruiterId]/edit/index.jsx'
import TestPage from './dashboard/test/index.jsx'
import CompanyPage from './dashboard/company/index.jsx'
import EditCompany from './dashboard/company/[companyId]/edit/index.jsx'

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const router = createBrowserRouter([
  {
    path:'/',
    element: <Home />
  },
  {
    
    element: <App />,
    children : [
      {
        path:'/dashboard',
        element:<Dashboard />
      },
      {
        path:'/dashboard/resume/',
        element:<ResumePage />
      },
      {
        path:'/dashboard/resume/:resumeId/edit',
        element:<EditResume />
      },
      {
        path:'/dashboard/jobapplication/',
        element:<JobApplicationPage />
      },
      {
        path:'/dashboard/jobapplication/:jobApplicationId/edit',
        element:<EditJobApplication />
      },
      {
        path:'/dashboard/recruiter/',
        element:<RecruiterPage />
      },
      {
        path:'/dashboard/recruiter/:recruiterId/edit',
        element:<EditRecruiter />
      },
      {
        path:'/dashboard/company/',
        element:<CompanyPage />
      },
      {
        path:'/dashboard/company/:companyId/edit',
        element:<EditCompany />
      },
      
      
    ]
  }, 
  {
    path:'/auth/sign-in',
    element: <SignInPage />
  },
  {
    path:'/my-resume/:resumeId/view',
    element:<ViewResume />
  },
  {
    path:'/test',
    element: <TestPage />
  },
  
]);

initializeGA();

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
    <RouterProvider router={router} />
    </ClerkProvider>
    
  </React.StrictMode>,
)
