import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { JobApplicationInfoContext } from '@/context/JobApplicationInfoContext';
import CmsApi from './../../../../../service/CmsApi';
import FormSection from '../../components/FormSection';


const EditJobApplication = () => {
    const { jobApplicationId } = useParams();
    const [jobApplication,setJobApplication]=useState();

    useEffect(() => {
        GetJobApplication();
    }, [])

    const GetJobApplication=()=>{
        // console.log('jobApplicationId:' + jobApplicationId)
        CmsApi.jobApplication.getJobApplicationById(jobApplicationId).then(resp=>{
          console.log(resp.data.data);
          setJobApplication(resp.data.data);
        })
    }

    return (
        <JobApplicationInfoContext.Provider value={{jobApplication,setJobApplication}}>
            <div className='grid grid-cols-1 md:grid-cols-2 p-10 gap-10'>
                {/* Form Section  */}
                <FormSection />
                {/* Preview Section  */}
                

            </div>
        </JobApplicationInfoContext.Provider>
    )
}

export default EditJobApplication