import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RecruiterInfoContext } from '@/context/RecruiterInfoContext';
import CmsApi from './../../../../../service/CmsApi';
import FormSection from '../../components/FormSection';


const EditRecruiter = () => {
    const { recruiterId } = useParams();
    const [recruiter,setRecruiter]=useState();

    useEffect(() => {
        GetJobApplication();
    }, [])

    const GetJobApplication=()=>{
        console.log('recruiterId:' + recruiterId)
        CmsApi.recruiter.getRecruiterById(recruiterId).then(resp=>{
          console.log(resp.data.data);
          setRecruiter(resp.data.data);
        })
    }

    return (
        <RecruiterInfoContext.Provider value={{recruiter, setRecruiter}}>
            <div className='grid grid-cols-1 md:grid-cols-2 p-10 gap-10'>
                {/* Form Section  */}
                <FormSection />
                {/* Preview Section  */}
                

            </div>
        </RecruiterInfoContext.Provider>
    )
}

export default EditRecruiter