import React, { useState } from 'react'
import { Home } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Link, Navigate, useParams } from 'react-router-dom';
import ApplicationDetailForm from './forms/ApplicationDetailForm';

const FormSection = () => {

  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='flex gap-5'>
          <Link to={"/dashboard/recruiter"}>
            <Button><Home /></Button>
          </Link>

        </div>

      </div>
      {/* Personal Detail  */}
      <ApplicationDetailForm />
      

    </div>
  )
}

export default FormSection