
import axios from "axios";

const API_KEY = import.meta.env.VITE_STRAPI_API_KEY;
const axiosClient = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL + "/api/",
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${API_KEY}`
    }
});

const CreateNewResume = (data) => axiosClient.post('/user-resumes', data);

const GetUserResumes = (userEmail) => axiosClient.get('/user-resumes?filters[userEmail][$eq]=' + userEmail);

const GetResumeCount = async (userEmail) => {
    try {
      const response = await axiosClient.get(`/user-resumes?filters[userEmail][$eq]=${userEmail}`);
      return response.data.data.length;
    } catch (error) {
      console.error('Error fetching resume count:', error);
      throw error;
    }
  };

const UpdateResumeDetail = (id, data) => axiosClient.put('/user-resumes/' + id, data);

const GetResumeById = (id) => axiosClient.get('/user-resumes/' + id + "?populate=*");

const DeleteResumeById = (id) => axiosClient.delete('/user-resumes/' + id);

export default {
    CreateNewResume,
    GetUserResumes,
    GetResumeCount,
    UpdateResumeDetail,
    GetResumeById,
    DeleteResumeById
};
