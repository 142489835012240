import React, { useEffect, useState } from 'react'
import { useUser } from '@clerk/clerk-react'
import CmsApi from './../../service/CmsApi';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  Activity,
  CreditCard,
  DollarSign,
  Users,
} from "lucide-react"
import { Link } from 'react-router-dom'
import { Separator } from "@/components/ui/separator"

const Dashboard = () => {
  const { user } = useUser();

  const [jobApplicationCount, setJobApplicationCount] = useState(0);
  const [resumeCount, setResumeCount] = useState(0);
  const [recruiterCount, setRecruiterCount] = useState(0);
  const [companyCount, setCompanyCount] = useState(0);


  useEffect(() => {
    if (user) {
      GetJobApplicationCount()
      GetResumeCount()
      GetRecruiterCount()
      GetCompanyCount()
    }
  }, [user])

  const GetJobApplicationCount = () => {
    CmsApi.jobApplication.getProcessJobApplicationsCount(user?.primaryEmailAddress?.emailAddress)
      .then(count => {
        console.log(count)
        setJobApplicationCount(count);
      })
  }

  const GetResumeCount = () => {
    CmsApi.resume.GetResumeCount(user?.primaryEmailAddress?.emailAddress)
      .then(count => {
        console.log(count)
        setResumeCount(count);
      })
  }

  const GetRecruiterCount = () => {
    CmsApi.recruiter.GetRecruiterCount(user?.primaryEmailAddress?.emailAddress)
      .then(count => {
        console.log(count)
        setRecruiterCount(count);
      })
  }

  const GetCompanyCount = () => {
    CmsApi.company.getCompanyCount(user?.primaryEmailAddress?.emailAddress)
      .then(count => {
        console.log(count)
        setCompanyCount(count);
      })
  }





  return (
    <div className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
        <Link to={'/dashboard/jobapplication'}>
          <Card x-chunk="dashboard-01-chunk-0">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Job Application
              </CardTitle>
              <Activity className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{jobApplicationCount}</div>
              <p className="text-xs text-muted-foreground">
                in process and pending job applications
              </p>
            </CardContent>
          </Card>
        </Link>
        

      </div>
      <Separator className="my-4" />
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
        <Link to={'/dashboard/recruiter'}>
          <Card x-chunk="dashboard-01-chunk-1">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Recruiter
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{recruiterCount}</div>
              <p className="text-xs text-muted-foreground">
                recruiter count...
              </p>
            </CardContent>
          </Card>
        </Link>
        <Link to={'/dashboard/company'}>
          <Card x-chunk="dashboard-01-chunk-1">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Company
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{companyCount}</div>
              <p className="text-xs text-muted-foreground">
                company count...
              </p>
            </CardContent>
          </Card>
        </Link>
        <Link to={'/dashboard/resume'}>
          <Card x-chunk="dashboard-01-chunk-1">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Resume
              </CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{resumeCount}</div>
              <p className="text-xs text-muted-foreground">
                resume count...
              </p>
            </CardContent>
          </Card>
        </Link>
        
      </div>
    </div>
  )
}

export default Dashboard