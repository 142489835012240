import { Loader2, PlusSquare } from 'lucide-react'
import React, { useState } from 'react'
import {
    PlusCircle,
} from "lucide-react"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useUser } from '@clerk/clerk-react'
import { v4 as uuidv4 } from 'uuid';
import CmsApi from '../../../../service/CmsApi'
import { Navigate, useNavigate } from 'react-router-dom'

const AddCompany = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [company, setCompany] = useState();
    const { user } = useUser();

    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();

    const onCreate = async () => {
        setLoading(true)
        const uuid = uuidv4();
        console.log(company, uuid);

        const data = {
            data: {
                name: company,
                companyId: uuid,
                userEmail: user?.primaryEmailAddress?.emailAddress
            }
        }

        CmsApi.company.createCompany(data).then(resp => {
            console.log(resp.data.data.documentId);
            if (resp) {
                setLoading(false);
                navigation('/dashboard/company/' + resp.data.data.documentId + "/edit");
            }
        }, (error) => {
            setLoading(false);
        })
    }


    return (
        <div>
            <div className="ml-auto flex items-center gap-2" onClick={() => setOpenDialog(true)}>
                <Button size="sm" className="h-8 gap-1" >
                    <PlusCircle className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only sm:whitespace-nowrap ">
                        Add Company
                    </span>
                </Button>
            </div>

            <Dialog open={openDialog}>

                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Create New Company</DialogTitle>
                        <DialogDescription>
                            <p>Add a Company Name</p>
                            <Input className="my-2"
                                placeholder="Ex.Bob Jones"
                                onChange={(e) => setCompany(e.target.value)}
                            />
                        </DialogDescription>
                        <div className='flex justify-end gap-5'>
                            <Button onClick={() => setOpenDialog(false)} variant="ghost">Cancel</Button>
                            <Button disabled={!company || loading}
                                onClick={() => onCreate()} >
                                {loading ?
                                    <Loader2 className='animate-spin' /> : 'Create'
                                }
                            </Button>
                        </div>
                    </DialogHeader>
                </DialogContent>
            </Dialog>

        </div>
    )
}

export default AddCompany