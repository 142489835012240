import axios from 'axios';

const API_KEY = import.meta.env.VITE_STRAPI_API_KEY;
const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + "/api/",
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_KEY}`
  }
});

const createCompany = (data) => axiosClient.post('/companies', data);

const getCompanies = (userEmail) => axiosClient.get('/companies?filters[userEmail][$eq]=' + userEmail);

const updateCompany = (id, data) => axiosClient.put('/companies/' + id, data);

const getCompanyById = (id) => axiosClient.get('/companies/' + id + "?populate=*");

const deleteCompany = (id) => axiosClient.delete('/companies/' + id);

const getCompanyCount = async (userEmail) => {
  try {
    const response = await axiosClient.get(`/companies?filters[userEmail][$eq]=${userEmail}`);
    return response.data.data.length;
  } catch (error) {
    console.error('Error fetching companies count:', error);
    throw error;
  }
};


export default {
  createCompany,
  getCompanies,
  updateCompany,
  getCompanyById,
  deleteCompany,
  getCompanyCount,
};
