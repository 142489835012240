import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { RecruiterInfoContext } from '@/context/RecruiterInfoContext'
import { LoaderCircle } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CmsApi from './../../../../../service/CmsApi';
import { toast } from 'sonner';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Textarea } from '@/components/ui/textarea'

function ApplicationDetailForm() {

    const params = useParams();
    const { recruiter, setRecruiter } = useContext(RecruiterInfoContext)

    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false);

    const recruiterTypes = CmsApi.recruiter.RECRUITER_TYPES;

    useEffect(() => {
        console.log('=ApplicationDetailForm=');
        console.log(params);
        console.log(recruiter)
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        })
        setRecruiter({
            ...recruiter,
            [name]: value
        })
    }

    const handleTypeChange = (value) => {
        setFormData({
            ...formData,
            type: value
        })
        setRecruiter({
            ...recruiter,
            type: value
        })
    }

    const onSave = (e) => {
        e.preventDefault();
        setLoading(true)
        console.log('formData--')
        console.log(formData)
        const data = {
            data: formData
        }
        CmsApi.recruiter.updateRecruiter(params?.recruiterId, data).then(resp => {
            console.log(resp);
            setLoading(false);
            toast("Recruiter Details updated")
        }, (error) => {
            setLoading(false);
        })

    }
    return (
        <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
            <h2 className='font-bold text-lg'>Recruiter Detail</h2>
            <p>manager the basic information</p>

            <form onSubmit={onSave}>
                {recruiter && <div className='grid grid-cols-2 mt-5 gap-3'>
                    <div>
                        <label className='text-sm'>Name</label>
                        <Input name="name" defaultValue={recruiter?.name} required onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className='text-sm'>Company</label>
                        <Input name="company" required onChange={handleInputChange}
                            defaultValue={recruiter?.company} />
                    </div>
                    <div>
                        <label className='text-sm'>Email</label>
                        <Input name="email" onChange={handleInputChange}
                            defaultValue={recruiter?.email} />
                    </div>
                    <div>
                        <label className='text-sm'>Phone</label>
                        <Input name="phone" onChange={handleInputChange}
                            defaultValue={recruiter?.phone} />
                    </div>

                    <div>
                        <label className='text-sm'>Type</label>
                        <Select name="type" defaultValue={recruiter?.type} onValueChange={handleTypeChange}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a type" />
                            </SelectTrigger>
                            <SelectContent>
                                {recruiterTypes.map(type => (
                                    <SelectItem key={type} value={type}>
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>

                    <div className='col-span-2'>
                        <label>Description</label>
                        <Textarea name="description"
                            onChange={handleInputChange}
                            defaultValue={recruiter?.description} />
                    </div>
                    <div className='col-span-2'>
                        <label className='text-sm'>Linkedin URL</label>
                        <Input name="linkedinUrl" 
                            defaultValue={recruiter?.linkedinUrl}
                            onChange={handleInputChange} />
                    </div>

                </div>}

                <div className='mt-3 flex justify-end'>
                    <Button type="submit"
                        disabled={loading}>
                        {loading ? <LoaderCircle className='animate-spin' /> : 'Save'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ApplicationDetailForm