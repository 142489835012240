import React, { useState, useEffect } from 'react'
import CmsApi from './../../../service/CmsApi';
import { useUser } from '@clerk/clerk-react'
import {
    Loader2Icon,
    MoreHorizontal,
    PlusCircle,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { Badge } from "@/components/ui/badge"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import AddRecruiter from './components/AddRecruiter';
import { toast } from 'sonner'
import { Link, useNavigate } from 'react-router-dom'

const RecruiterPage = () => {
    const { user } = useUser();
    const [recruiters, setRecruiters] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRecruiter, setSelectedRecruiter] = useState({ documentId: null, jobTitle: '', company: '' });

    useEffect(() => {
        user && GetRecruiterList()
    }, [user])

    const GetRecruiterList = () => {
        console.log(user?.primaryEmailAddress?.emailAddress)
        CmsApi.recruiter.getRecruiters(user?.primaryEmailAddress?.emailAddress)
            .then(resp => {
                console.log('CmsApi.recruiter.getRecruiters ...')
                console.log(resp.data.data)
                if (resp.data.data) {
                    setRecruiters(resp.data.data);
                }

            })
    }

    const onDelete = () => {
        setLoading(true);
        CmsApi.recruiter.deleteRecruiter(selectedRecruiter.documentId).then(resp => {
            console.log(resp);
            toast('Recruiter Deleted!');
            GetRecruiterList()
            setLoading(false);
            setOpenAlert(false);
        }, (error) => {
            setLoading(false);
        })
    }

    const handleDeleteClick = (recruiter) => {
        setSelectedRecruiter({ documentId: recruiter.documentId, name: recruiter.name, company: recruiter.company });
        setOpenAlert(true);
    }


    return (
        <div>

            <div className="flex min-h-screen w-full flex-col bg-muted/40">


                <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">


                    <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
                        <Tabs defaultValue="all">

                            <div className="flex items-center">
                                <AddRecruiter />
                            </div>
                            <TabsContent value="all">
                                <Card x-chunk="dashboard-06-chunk-0">
                                    <CardHeader>
                                        <CardTitle>Recruiter List</CardTitle>
                                        <CardDescription>
                                            Manage your recruiter .
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead>Recruiter Name</TableHead>
                                                    <TableHead className="hidden md:table-cell">
                                                        Company
                                                    </TableHead>
                                                    <TableHead className="hidden md:table-cell">
                                                        Type
                                                    </TableHead>

                                                    <TableHead className="hidden md:table-cell">
                                                        email
                                                    </TableHead>
                                                    <TableHead>phone</TableHead>
                                                    <TableHead className="hidden md:table-cell">
                                                        Update at
                                                    </TableHead>
                                                    <TableHead>
                                                        <span className="sr-only">Actions</span>
                                                    </TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>

                                                {recruiters && recruiters.map((recruiter) => (
                                                    <TableRow key={recruiter.id}>

                                                        <TableCell className="font-medium">
                                                            {recruiter.linkedinUrl ? (
                                                                <a href={recruiter.linkedinUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                                                    {recruiter.name}
                                                                </a>
                                                            ) : (
                                                                <span className="text-gray-700">{recruiter.name}</span>
                                                            )}
                                                        </TableCell>
                                                        <TableCell className="hidden md:table-cell">
                                                            {recruiter.company}
                                                        </TableCell>
                                                        <TableCell className="hidden md:table-cell">
                                                            {recruiter.type}
                                                        </TableCell>


                                                        <TableCell className="hidden md:table-cell">
                                                            {recruiter.email}
                                                        </TableCell>
                                                        <TableCell>
                                                            {recruiter.phone}
                                                        </TableCell>
                                                        <TableCell className="hidden md:table-cell">
                                                            {recruiter.updatedAt}
                                                        </TableCell>

                                                        <TableCell>
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button
                                                                        aria-haspopup="true"
                                                                        size="icon"
                                                                        variant="ghost"
                                                                    >
                                                                        <MoreHorizontal className="h-4 w-4" />
                                                                        <span className="sr-only">Toggle menu</span>
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent align="end">
                                                                    <DropdownMenuLabel>Actions</DropdownMenuLabel>
                                                                    <Link to={'/dashboard/recruiter/' + recruiter.documentId + "/edit"}><DropdownMenuItem>Edit&Details</DropdownMenuItem></Link>
                                                                    <DropdownMenuItem className="text-red-500 hover:text-red-700" onClick={() => handleDeleteClick(recruiter)}>Delete</DropdownMenuItem>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                    <CardFooter>
                                        <div className="text-xs text-muted-foreground">
                                            Showing <strong>{recruiters && recruiters.length}</strong>{" "}
                                            recruiters
                                        </div>
                                    </CardFooter>
                                </Card>
                            </TabsContent>
                        </Tabs>

                        <AlertDialog open={openAlert}>

                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This action cannot be undone. This will permanently delete the recruiter for <strong>{selectedRecruiter.name}</strong> at {selectedRecruiter.company} and remove your data from our servers.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setOpenAlert(false)}>Cancel</AlertDialogCancel>
                                    <AlertDialogAction className="bg-red-500 hover:bg-red-700 text-white" onClick={onDelete}
                                        disabled={loading}>
                                        {loading ? <Loader2Icon className='animate-spin' /> : 'Delete'}
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecruiterPage