
import './App.css'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useUser } from '@clerk/clerk-react'
import Header from './components/custom/Header';
import { Toaster } from './components/ui/sonner';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    
    const pagesUrl = location.pathname + location.search;
    // console.log('send new pages : ' + pagesUrl)
    ReactGA.send({ hitType: 'pageview', page: pagesUrl, title: pagesUrl});
  }, [location]);
};

function App() {
  usePageTracking();
  const {user,isLoaded,isSignedIn}=useUser();

  if(!isSignedIn&&isLoaded)
  {
    return <Navigate to={'/auth/sign-in'} />
  }
  return (
    <>
      <Header />
      <Outlet />
      <Toaster />
    </>
  )
}

export default App
