import React, { useEffect, useState } from 'react'
import AddResume from './components/AddResume'
import { useUser } from '@clerk/clerk-react'
import CmsApi from './../../../service/CmsApi';
import ResumeCardItem from './components/ResumeCardItem';

const ResumePage = () => {

  const { user } = useUser();
  const [resumeList, setResumeList] = useState([]);

  useEffect(() => {
    user && GetResumesList()
  }, [user])

  /**
   * Used to Get Users Resume List
   */
  const GetResumesList = () => {
    CmsApi.resume.GetUserResumes(user?.primaryEmailAddress?.emailAddress)
      .then(resp => {
        console.log(resp.data.data)
        setResumeList(resp.data.data);
      })
  }

  return (
    <div>
      <div className='p-10 md:px-20 lg:px-32'>
        <h2 className='font-bold text-3xl'>My Resume</h2>
        <p>Start Creating AI resume to your next Job role</p>
        <div className='grid grid-cols-2 
          md:grid-cols-3 lg:grid-cols-5 gap-5
          mt-10 '>
          <AddResume />
          {resumeList.length > 0 ? resumeList.map((resume, index) => (
            <ResumeCardItem resume={resume} key={index} refreshData={GetResumesList} />
          )) :
            [1, 2, 3, 4].map((item, index) => (
              <div className='h-[280px] rounded-lg bg-slate-200 animate-pulse' key={index}>
              </div>
            ))
          }
        </div>

      </div>
    </div>
  )
}

export default ResumePage