import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { CompanyInfoContext } from '@/context/CompanyInfoContext'
import { LoaderCircle } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CmsApi from './../../../../../service/CmsApi';
import { toast } from 'sonner';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Textarea } from '@/components/ui/textarea'

function ApplicationDetailForm() {

    const params = useParams();
    const { company, setCompany } = useContext(CompanyInfoContext)

    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        console.log('=ApplicationDetailForm=');
        console.log(params);
        console.log(company)
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        })
        setCompany({
            ...company,
            [name]: value
        })
    }


    const onSave = (e) => {
        e.preventDefault();
        setLoading(true)
        console.log('formData--')
        console.log(formData)
        const data = {
            data: formData
        }
        CmsApi.company.updateCompany(params?.companyId, data).then(resp => {
            console.log(resp);
            setLoading(false);
            toast("Company Details updated")
        }, (error) => {
            setLoading(false);
        })

    }
    return (
        <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
            <h2 className='font-bold text-lg'>Company Detail</h2>
            <p>manager the basic information</p>

            <form onSubmit={onSave}>
                {company && <div className='grid grid-cols-2 mt-5 gap-3'>
                    <div>
                        <label className='text-sm'>Name</label>
                        <Input name="name" defaultValue={company?.name} required onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className='text-sm'>City</label>
                        <Input name="city" required onChange={handleInputChange}
                            defaultValue={company?.city} />
                    </div>
                    <div>
                        <label className='text-sm'>URL</label>
                        <Input name="url" onChange={handleInputChange}
                            defaultValue={company?.url} />
                    </div>

                    <div className='col-span-2'>
                        <label>Description</label>
                        <Textarea name="description"
                            onChange={handleInputChange}
                            defaultValue={company?.description} />
                    </div>

                </div>}

                <div className='mt-3 flex justify-end'>
                    <Button type="submit"
                        disabled={loading}>
                        {loading ? <LoaderCircle className='animate-spin' /> : 'Save'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ApplicationDetailForm