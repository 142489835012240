import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { JobApplicationInfoContext } from '@/context/JobApplicationInfoContext'
import { LoaderCircle } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CmsApi from './../../../../../service/CmsApi';
import { toast } from 'sonner';
import RichTextEditorForJD from '../RichTextEditorForJD';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Textarea } from '@/components/ui/textarea'

function ApplicationDetailForm() {

    const params = useParams();
    const { jobApplication, setJobApplication } = useContext(JobApplicationInfoContext)

    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false);

    const jobStates = CmsApi.jobApplication.JOB_STATES;
    const jobSources  = CmsApi.jobApplication.JOB_SOURCES;
    const jobPriority = CmsApi.jobApplication.JOB_PRIORITY;

    useEffect(() => {
        console.log('=ApplicationDetailForm=');
        console.log(params);
        console.log(jobApplication)
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        })
        setJobApplication({
            ...jobApplication,
            [name]: value
        })
    }

    const handleStateChange = (value) => {
        setFormData({
            ...formData,
            state: value
        })
        setJobApplication({
            ...jobApplication,
            state: value
        })
    }

    const handlePriorityChange = (value) => {
        setFormData({
            ...formData,
            priority: value
        })
        setJobApplication({
            ...jobApplication,
            priority: value
        })
    }

    const handleSourceChange = (value) => {
        setFormData({
            ...formData,
            source: value
        })
        setJobApplication({
            ...jobApplication,
            source: value
        })
    }

    const onSave = (e) => {
        e.preventDefault();
        setLoading(true)
        console.log('formData--')
        console.log(formData)
        const data = {
            data: formData
        }
        CmsApi.jobApplication.updateJobApplication(params?.jobApplicationId, data).then(resp => {
            console.log(resp);
            setLoading(false);
            toast("Job Application Details updated")
        }, (error) => {
            setLoading(false);
        })

    }
    return (
        <div className='p-5 shadow-lg rounded-lg border-t-primary border-t-4 mt-10'>
            <h2 className='font-bold text-lg'>Job Application Detail</h2>
            <p>manager the basic information</p>

            <form onSubmit={onSave}>
                {jobApplication && <div className='grid grid-cols-2 mt-5 gap-3'>
                    <div>
                        <label className='text-sm'>Job Title</label>
                        <Input name="jobTitle" defaultValue={jobApplication?.jobTitle} required onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className='text-sm'>Company</label>
                        <Input name="company" required onChange={handleInputChange}
                            defaultValue={jobApplication?.company} />
                    </div>
                    <div>
                        <label className='text-sm'>Apply Date</label>
                        <Input name="applyDate" type="date" required
                            defaultValue={jobApplication?.applyDate}
                            onChange={handleInputChange} />
                    </div>

                    <div>
                        <label className='text-sm'>State</label>
                        <Select name="state" defaultValue={jobApplication?.state} onValueChange={handleStateChange}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a state" />
                            </SelectTrigger>
                            <SelectContent>
                                {jobStates.map(state => (
                                    <SelectItem key={state} value={state}>
                                        {state}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <label className='text-sm'>Priority</label>
                        <Select name="priority" defaultValue={jobApplication?.priority} onValueChange={handlePriorityChange}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a priority" />
                            </SelectTrigger>
                            <SelectContent>
                                {jobPriority.map(priority => (
                                    <SelectItem key={priority} value={priority}>
                                        {priority}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <label className='text-sm'>Tag</label>
                        <Input name="tag" 
                            defaultValue={jobApplication?.tag}
                            onChange={handleInputChange} />
                    </div>
                    <div>
                        <label className='text-sm'>City</label>
                        <Input name="city" 
                            defaultValue={jobApplication?.city}
                            onChange={handleInputChange} />
                    </div>
                    
                    <div>
                        <label className='text-sm'>Source</label>
                        <Select name="source" defaultValue={jobApplication?.source} onValueChange={handleSourceChange} required>
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Source" />
                            </SelectTrigger>
                            <SelectContent>
                                {jobSources.map(source => (
                                    <SelectItem key={source} value={source}>
                                        {source}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                    <div>
                        <label className='text-sm'>Due Date</label>
                        <Input name="dueDate" type="date"
                            defaultValue={jobApplication?.dueDate}
                            onChange={handleInputChange} />
                    </div>
                    <div className='col-span-2'>
                        <label>Description</label>
                        <Textarea name="description"
                            onChange={handleInputChange}
                            defaultValue={jobApplication?.description} />
                    </div>
                    <div className='col-span-2'>
                        <label className='text-sm'>JD URL</label>
                        <Input name="jdUrl" 
                            defaultValue={jobApplication?.jdUrl}
                            onChange={handleInputChange} />
                    </div>
                    <div className='col-span-2'>
                        <RichTextEditorForJD
                            name={"jobDescription"}
                            defaultValue={jobApplication?.jobDescription}
                            onRichTextEditorChange={handleInputChange} />
                    </div>

                </div>}

                <div className='mt-3 flex justify-end'>
                    <Button type="submit"
                        disabled={loading}>
                        {loading ? <LoaderCircle className='animate-spin' /> : 'Save'}
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ApplicationDetailForm